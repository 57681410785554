import React, { useEffect } from 'react';
import "./Intro.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Config from '../../Config';

const Intro2 = () => {
    const navigate= useNavigate();
    const locate = window.location.href;
    const param = locate.split("/");
  console.log("parsm", param)
  const email= param[3]

    useEffect(()=>{
      let body= {
          "email" :  email
        }
        let url= `${Config.host}/signup`
        axios.post(url , body)
        .then(( res )=>{
          console.log(res, "res")
        if( res.data.response === 'success' ){
          sessionStorage.setItem('scoreId', res.data.score_id )
        }
        })
        .catch(err=>{
          console.log("Err", err)
        })
        
   
  
    },[])

    // const param = locate.split("/")
    console.log(param[4], "my_list")

    const handleNavigate=()=>{
        navigate(`/medeasy/${param[3]}`)
    }
  return (
    <div className='introCont'>
        <div className='introleft'>
            {/* <div ><img className='complog' src="/Logo.png" alt=""/></div> */}

            <div><img className='leftimg' src="/leftimg.png" alt=""/></div>
            {/* <p className='introlefttitle'>Virtual Radiology Assistant</p>

            <p className='introlefttitlemini'>Interview Round 1</p>
            <p className='introleftpara'>We're excited to have you here! This assessment is an opportunity for you to showcase your skills and knowledge, and an opportunity for us to understand your suitability to the role</p> */}
        </div>
        <div className='introright'>
           <p className='introrighttitle2'>Welcome to the Language Training Section!</p>
           <div className='intro2whitebox'>
            {/* <div className='intro2keydiv'>
           <p className='headingkeydiv'>Medical Terminology Assessment</p>
            <p className='kdquestion'>20 MCQ'S</p>
            </div> */}
            
            <div className='intro2keydiv'>
           <p className='headingkeydiv'>Writing/Listening/Speaking Evaluation</p>
           {/* <p className='kdtime'>(20 Minutes)</p> */}
           <ul>
            <li className='kdquestion'>Grammar and Language Proficiency Skills Assessment</li>
            <li className='kdquestion'>Listening and Comprehension Skills Assessment</li>
            <li className='kdquestion'>Verbal Communication and Language Fluency Assessment</li>
           </ul>
            </div>
        
            {/* <div className='intro2keydiv'>
           <p className='headingkeydiv'>Critical Thinking and Aptitude Skills Assessment</p>
            <p className='kdquestion'>10 MCQ'S</p>
            </div> */}
        
           </div>

<div className='accbutdiv'>
           <button onClick={handleNavigate} className='accncont'>NEXT</button>
           </div>
        </div>
    </div>
  )
}

export default Intro2