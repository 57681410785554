



import React, { useEffect, useState } from 'react';
import './Voicetotext.css';
import { Input, notification, Spin } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import Config from "../../Config";

const Voicetotext = ({ onSuccess }) => {
  const [userResponses, setUserResponses] = useState([]);
  const [remainingTime, setRemainingTime] = useState(20 * 60);
  const [audioLinks, setAudioLinks] = useState([]);
  const [characterCounts, setCharacterCounts] = useState(Array(audioLinks.length).fill(0));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [clickCounts, setClickCounts] = useState(Array(audioLinks.length).fill(0));
  const [submitLoading, setSubmitLoading] = useState(false);
  const [ audioIds, setAudioIds ] = useState([]);


  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${Config.host}/audio_file`);
      const apiAudioLinks = response.data.audio_link;
      const audioId = apiAudioLinks.map(item => item.id)
      setAudioLinks(apiAudioLinks);
      setAudioIds(audioId)
      setLoading(false);
      setClickCounts(Array(apiAudioLinks.length).fill(0)); 

    } catch (error) {
      setLoading(false);
      console.error('Error fetching questions:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);
console.log(audioIds,"isAudioPlaying")
  const locate = window.location.href;
  const param = locate.split('/');
  const [ClickedIndex, setClickedIndex] = useState("")
  const playAudio = (audioUrl, index) => {
    console.log(audioUrl, index,"audioUrl")
    setClickedIndex(index)
    if (clickCounts[index] < 3) {
      setClickCounts((prevClickCounts) => {
        const updatedClickCounts = [...prevClickCounts];
        updatedClickCounts[index]++;
        return updatedClickCounts;
      });
      
      setIsAudioPlaying(true);

      const audio = new Audio(audioUrl);
      audio.play();

      audio.onended = () => {
        setIsAudioPlaying(false);
      };
    }
  };
  
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Warning",
      description:
        type,
    });
  };

  const scoreId = sessionStorage.getItem('scoreId')

  
  const handleSubmit = async () => {
    console.log(userResponses,"userResponses")
    
if(userResponses.length >= 5 ){
    setSubmitLoading(true);
    const allAnswersHaveValue = userResponses.every((item) => item.answer.trim() !== '');
    console.log(userResponses,"response",allAnswersHaveValue)
    if (!allAnswersHaveValue) {
      openNotificationWithIconError("Please provide an answer before submitting.");
      setSubmitLoading(false);
      return; 
    }else{
    try {
      const response = await axios.post(`${Config.host}/Listening`, {
        email: param[4],
        score_id : scoreId,
        user_answer: userResponses.map(response => response.answer), 
        audio_id: audioIds.map(audio => audio),
      });

      if (response.status === 200) {
        onSuccess();
      }
      console.log('Responses sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending responses:', error);
    } finally {
      setSubmitLoading(false);
    }
  }
  }else{
    setSubmitLoading(false);
    openNotificationWithIconError("Please provide an answer before submitting.");
  }
  };

  const handleAnswerChange = (index, answer) => {

      setUserResponses((prevResponses) => {
        const updatedResponses = [...prevResponses];
        updatedResponses[index] = { question: audioLinks[index], answer };
        return updatedResponses;
      });
    }


  return (
    <div className="rightwallpapervoice">
      {contextHolder}
    <div className="rightwallpapercontainervoi">
      <h1 className='voicelisthed'>Section 2 - Listening and Comprehension Skills Assessment</h1>
      <p>Please listen carefully and type the passage. You can only listen to each audio file thrice</p>

      {loading ? ( 
        <Spin size="large" />
      ) : (
        <>
          {audioLinks.map((audioLink, index) => (
            <div key={index} className="questionContainer">
              <p className='ButtonTag'>
                <button
                  className="cliknlist"
                  onClick={() => playAudio(audioLink.audio, index)}
                  disabled={isAudioPlaying || clickCounts[index] >= 3}
                >
                   {isAudioPlaying && index == ClickedIndex ?  "Playing..." :"Listen Here"}
                  <span>
                    <img style={{ height: "20px", width: "20px" }} src="/hear.png" alt="" />
                  </span>
                </button>      
              </p>


              <Input.TextArea
              className='voiceToTextAreaInput'
              style={{ resize: 'none' }}
                spellCheck='false'
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                placeholder="Type here.."
                value={userResponses[index]?.answer || ""}
              />
            </div>
          ))}


          <div>
            {submitLoading ? (
              <Spin className='submitSpin' size="large" />
            ) : (
              <button className="button" onClick={handleSubmit}>
                <div
                  className="buttoncontainer"
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '100%', 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>Submit&emsp;</span>
                  <span />
                </div>
              </button>
            )}
          </div>

        </>
      )}

      <div className="rightfooter">
      
      </div>
    </div>
  </div>
  );
};

export default Voicetotext;

