import React, { useEffect, useState } from 'react';
import './LRBrain.css';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const LRBrain = () => {
  const questions = [
    'Write a Python Program to Print All Letters Present in Both Strings',
    'Write a Python Program that Displays which Letters are in First String but not in Second',
    'Write a Python Program to Swap the First and the Last Character of a String',
    'Write a Python Program to Multiply All the Items in a Dictionary',
    'Write a Python Program to Count the Frequency of Each Word in a String using Dictionary',
    'Write a Python Program to Create a List of Tuples with the First Element as the Number and Second Element as the Square of the Number',
    'Write a Python Program to Create a Class which Performs Basic Calculator Operations',
    'Write a Python Program to Create a Class in which One Method Accepts a String from the User and Another Prints it',
    'Write a Python Program to Find Nth Node from End of Linked List',
    'Write a Python Program to Implement Queues using Stack',
    'Write a Python program to count the frequency of each element in a list.',
    'Write a Python program to find the common elements between two lists.',
  ];

  const [selectedQuestions, setSelectedQuestions] = useState([]);
//   const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const navigate= useNavigate()


  const handleAnswerChange = (index, answer) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[index] = { question: selectedQuestions[index], answer };
      return updatedResponses;
    });
  };


  const locate = window.location.href
  console.log(locate, "ee")

  const param = locate.split("/")
  console.log(param[4], "my_list")

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://54.89.129.247:9001/code_validator', {
        email:param[4],
        coding: userResponses,
        question: selectedQuestions,
        
        
        // coding: userResponses.map(({ question, answer }) => ({ question, answer })),
      });
      if(response.status== 200){
        navigate("/final")
      }
      console.log('Responses sent successfully:', response.data);
      // Handle any further actions or redirects here
    } catch (error) {
      console.error('Error sending responses:', error);
      // Handle errors
    }
  };

  useEffect(() => {
    // Shuffle the array
    const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
    // Select the first two questions
    const selected = shuffledQuestions.slice(0, 2);
    setSelectedQuestions(selected);
  }, []);

  return (
    <div className="loginwallpaper"> 
      {/* ... (your existing code) ... */}

      <div className='leftwallpaper'>
      {/* <div><img className='imgstyle' src="/Logo Svg OG 3.png"/></div> */}
            {/* <div><img style={{width:"300px", height: "48px", paddingTop:"5%"}} src="logo (4).jpg"/></div> */}
                <div className='leftwallpapercontainer'>
                    <div className='titlewallpaper'>
                        <span className='title'>Welcome to Precium AI Recuritment Platform</span>
                        {/* <span className='titlecontainer'>A platform to showcase your skills.</span> */}
                    </div>
                    {/* 10 general apt qu
10 technical interview questions 
5 cultural figment questions
2 python programming */}
                   <div className='itemwallpaper'>
                    <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span> Choose the correct answer</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskOutline */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Do not refresh</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span> Do not go back</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Do not open other tab</span>
                        </div>
                       
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Coding</span>
                        </div>  
                    </div>
                </div>
            </div>
   
      <div className="rightwallpaper">
        <div className="rightwallpapercontainer">
          <h1>Python Programming</h1>

          {selectedQuestions.map((question, index) => (
            <div key={index} className="questionContainer">
              <p>{question}</p>
              <Input.TextArea   onChange={(e) => handleAnswerChange(index, e.target.value)} placeholder="Your answer..." />
            </div>
          ))}

          <div>
            <button
              className="button"
              onClick={handleSubmit}
            >
              <div
                className="buttoncontainer"
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Submit&emsp;</span>
                <span />
              </div>
            </button>
          </div>

          <div className="rightfooter">
            <p>After selecting the option click on submit button for the next question</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LRBrain;
