import React ,{useEffect, useState} from 'react';
import './Med.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { ArrowForward } from '@material-ui/icons';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import MedHard from './MedHard';
import Speak from '../Speak/Speak';
import Interview from '../Home/Interview';
import Technical from '../Technical/Technical';
import Voicetotext from '../VoicetoText/Voicetotext';
import Transition1 from './Transition1';
import Transition2 from './Transition2';


const MedEasy = () => {

    const navigate = useNavigate();

     const [remainingTime, setRemainingTime] = useState(30 * 60)

     const [currentStep, setCurrentStep] = useState('technical');
     

     const locate = window.location.href
     

     const param = locate.split("/")
    //  console.log(param[4], "my_list")
   
    //  const handleMedHardSuccess = () => {
    //   // Handle any logic you need when MedHard is successful
    //   setCurrentStep('technical');
    // };
  
    const handleSpeakSuccess = () => {
      // Handle any logic you need when Speak is successful
      setCurrentStep('transition2');
    };

    const handleTechnicalSuccess=()=>{
      setCurrentStep('voicetotext')
    }
    const handleVoicetotextSuccess=()=>{
      // setCurrentStep()
      setCurrentStep('speak')
    }

    // const handleInterviewSuccess=()=>{
      
    //   navigate('final')
    // }


 
    // const handleTransition2Success=()=>{
    //  setCurrentStep('interview')
    // }

 

 
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timerInterval);
          navigate('final')
          return 0;
        }
      });
    }, 1000); 

    return () => clearInterval(timerInterval); 
},[])

    return (
      <div className='wholePageDiv'>
        <div className="loginwallpaper">
            <div className='leftwallpaper'>
      <div className='logosClass'>
        {/* <img src='/Logo.png' alt='vhsLogo' className='vhsimgstyle' /> */}
            {/* <div><img className='imgstyle' src="/hr.png"/></div> */}
            </div>  
                <div className='leftwallpapercontainer'>
                    <div className='titlewallpapermed'>
                        {/* <span className='title'>Welcome to AI Recruitment Hub</span>
                        <div className='titleminimed'>Your Gateway to Talent Acquisition Sucess!</div> */}
                    </div>
                    <div className='itemwallpaper'>
                    {/* <div className='loginitem'>
                            <span className='tickicon'>
                                <CheckCircleOutlined />
                            </span>
                            <span> Choose the correct answer</span>
                        </div> */}
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskOutline */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Total Sections : 3</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span> Total Time : 30 mins</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Do Not Refresh</span>
                        </div>
                
                    </div>
                </div>
            </div>
            {/* <hr />  */}
            <div className='rightwallpapermed'>
              <div className='timerrightmed'>
                <div className='remtime'>Remaining Time : </div> <div className='timerspan'>{Math.floor(remainingTime / 60)}:{(remainingTime % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
              </div>
              
               {/* {currentStep === 'medHard' && (
          <MedHard onSuccess={handleMedHardSuccess} />
        )} */}
     
        {currentStep === 'speak' && (
          <Speak onSuccess={handleSpeakSuccess} />
        )} 

        {currentStep === 'technical' && (
          <Technical onSuccess={handleTechnicalSuccess}/>
        )}

        {currentStep === 'voicetotext' && (
          <Voicetotext onSuccess={handleVoicetotextSuccess}/>
        )}
        
         {/* {currentStep === 'interview' && (
          <Interview onSuccess={handleInterviewSuccess}/>
        )} */}


      
           

           {/* {currentStep === 'transition2' && (
          <Transition2 onSuccess={handleTransition2Success}/>
        )} */}
            </div>
        </div>
        </div>
    )
}
export default MedEasy;