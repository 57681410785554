import React from 'react';
import "./Intro.css"
import { useNavigate } from 'react-router-dom';

const Intro = () => {
    const navigate= useNavigate()
    const locate = window.location.href
    console.log(locate, "ee")

    // const param = locate.split("/")
    // console.log(param[4], "my_list")

    const handleNavigate=()=>{
        navigate('/intro2')
    }
  return (
    <div className='introCont'>
        <div className='introleft'>
            {/* <div ><img className='complog' src="/Logo.png" alt=""/></div> */}
            <p className='introlefttitle'>Virtual Radiology Assistant</p>

            <p className='introlefttitlemini'>Interview Round 1</p>
            <p className='introleftpara'>We're excited to have you here! This assessment is an opportunity for you to showcase your skills and knowledge, and an opportunity for us to understand your suitability to the role</p>
        </div>
        <div className='introright'>
           <p className='introrighttitle'>Instructions</p>
           <div className='introwhitebox'>
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>Make sure you have a reliable internet connection.</p>
            </div>
            
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>Review your answers before submitting – Once you click “Next” you cannot go back to previous questions</p>
            </div>
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>The assessment should take approximately 40 minutes to complete.</p>
            </div>
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>A timer display is provided at the top right of the screen to assist you tracking your progress</p>
            </div>
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>If there are any interruptions (due to internet, power etc) after you begin the assessment, assessment will have to be taken from the beginning again & different set of questions will be provided.</p>
            </div>
            <div className='introkeydiv'>
            <div><img className='keyimg' src="/key.png" alt=""/></div>
            <p className='keyline'>Be honest and ethical</p>
            </div>

        

           </div>

           <div className='accbutdiv'>
           <button onClick={handleNavigate} className='accncont'>NEXT</button>
           </div>
        </div>
    </div>
  )
}

export default Intro