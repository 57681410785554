


import React, { useEffect, useState } from 'react';
import './Interview.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spin,Radio ,notification} from 'antd';
import Config from "../../Config";

const Interview = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userInput, setUserInput] = useState('');
    const [questions, setQuestions] = useState([]);
    const [userResponses, setUserResponses] = useState([]);
    const [remainingTime, setRemainingTime] = useState(10 * 60);
    const [correctAnswers, setCorrectAnswers] = useState([]);
    const [ButtonLoading, setButtonLoading] = useState(false);

    const locate = window.location.href;
    const param = locate.split("/");
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIconError = (type) => {
      api['error']({
        message: "Warning",
        description:
          type,
      });
    };
    const handleRadioChange = (e) => {
        setUserInput(e);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Config.host}/aptitude`);
            const apiQuestions = response.data.links;
            const correctAnswersArray = apiQuestions.map((q) => q.answer);

            setQuestions(apiQuestions);
            setCorrectAnswers(correctAnswersArray);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const handleSubmit = async () => {
        const selectedChoice = document.querySelector('input[name="choice"]:checked');
        const choiceValue = selectedChoice ? selectedChoice.value : '';

        if (choiceValue) {
        setUserResponses((prevResponses) => [...prevResponses, choiceValue]);
        }
        if (currentQuestionIndex + 1 <= questions.length) {
            if (!choiceValue) {
                openNotificationWithIconError("Please select an option before moving to the next question.");
                return;
              }
            
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
            try {
                setButtonLoading(true);
                const response = await axios.post(`${Config.host}/aptitude_validator`, {
                    email: param[4],
                    crt_answer: correctAnswers,
                    user_answer: userResponses,
                });

                if (response.status === 200) {
                    navigate('/final');
                }
            } catch (error) {
                console.error('Error sending responses:', error);
            } finally {
                setButtonLoading(false);
            }
        }
    };

    return (
        <div className='rightwallpaperhom'>
              {contextHolder}
            <div className='rightwallpapercontainer'>
                <h1 style={{ color: "#EC7224" }} className='aptitudehead'>Critical Thinking and Aptitude Skills Assessment</h1>

                {loading ? (
                    <Spin size="large" style={{ margin: '20px 0' }} />
                ) : (
                    <>
                        {questions?.length > 0 && (
                            <div>
                                <h2 className='questionmcqapt'>{questions[currentQuestionIndex]?.question}</h2>
                                {questions[currentQuestionIndex]?.options?.map((choice, index) => (
                                    <div key={index} className="choice-box" 
                                    onClick={() => handleRadioChange(choice)} >
                                        <Radio
                                            type="radio"
                                            id={`choice_${index}`}
                                            name="choice"
                                            value={choice}
                                            style={{ marginLeft: "30px" }}
                                            onChange={handleRadioChange}
                                            checked={userInput === choice}
                                        />
                                        {choice}
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}

                {!loading && (
                    <div>
                        <button className='button' disabled={ButtonLoading} onClick={handleSubmit}>
                            <div className='buttoncontainer'
                                style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    lineHeight: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                {currentQuestionIndex < questions.length ?
                                    <span>Next&emsp;</span>
                                    :
                                    <span>Submit&emsp;</span>
                                }
                                <span>
                                    {ButtonLoading ? <Spin /> : null}
                                </span>
                            </div>
                        </button>
                        <div className='rightfooter'>
                            <p>After selecting the option click on Next button for next question</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Interview;
