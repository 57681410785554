import React, { useState } from 'react';
import { REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../Constant";
import AWS from 'aws-sdk';
import axios from 'axios';

const AudioRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const s3 = new AWS.S3();
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle:true,
    }),
  });
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        setAudioBlob(blob);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const sendDataToApi = async () => {
    if (audioBlob) {
        const timestamp = new Date().toISOString();
        // const formData = new FormData();
        // formData.append('audio', audioBlob);
        console.log(audioBlob,'hi')
            const params = {  
              Bucket: 'subashpublic',
              Key: `Recording_${timestamp}.wav`, // Update UserID and BotID accordingly
              Body: audioBlob,
            };
            console.log(audioBlob,'hi',params)
      
            return new Promise((resolve, reject) => {
              s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
                .on('httpUploadProgress', (progress) => {
                  const uploadedPercent = Math.round((progress.loaded / progress.total) * 100);
               
                })
                .send((err, data) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve(data);
                    console.log(data,"dataa")
                  }
                });
            });
          
      
            
            // .then(() => {
            //   let body = {
            //   //   urls: fileList.map(file => `JobId/${file.name}`), 
            //   S3Link: data 
      
            //   };
      
            //   axios
            //     .post('https://api-hiringtalentpro.valuehealth.ai/bulk_upload', body)
            //     .then((res) => {
            //       message.success('File Uploaded and vector creation started');
            //       setResultData(res.data);
            //       // Handle your navigation or other actions here
            //     })
            //     .catch((err) => {
            //       console.log(err, "s3err");
            //       setVectorError('Upload Failed');
            //     })
            //     .finally(() => {
            //       setLoading(false);
            //       setFileList([]);
            //     });
            // })
            // .catch((err) => {
            //   console.log(err, "upload err");
            //   // Handle error
            // });
    } 
  };

  return (
    <div>
      <button onClick={recording ? stopRecording : startRecording}>
        {recording ? 'Stop Recording' : 'Start Recording'}
      </button>
      {audioBlob && (
        <div>
          <audio controls>
            <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
          </audio>
          <button onClick={sendDataToApi}>Send Audio</button>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
