import React, { useEffect, useState } from 'react';
import './Communication.css';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Communication = () => {

    const navigate=useNavigate()
  const questions = [
    "1. Why did you choose this course?",
"2. Where do you see yourself in 2-3 three years?",
"3. What's your short - term and long term goal?"
  ];


  const [userResponses, setUserResponses] = useState([]);



  const locate = window.location.href
  console.log(locate, "ee")

  const param = locate.split("/")
  console.log(param[4], "my_list")

  console.log(userResponses, "userresp")

const handleResponseChange = (index, response) => {
    const updatedResponses = [...userResponses];
    updatedResponses[index] = response;
    setUserResponses(updatedResponses);
  };

const handleSubmit = async () => {
    try {
      const response = await axios.post('https://hr-agent-prod.precium.ai/Communication', {
        email: param[4],
        response: userResponses,
      });

      if (response.status === 200) {
        navigate(`/technical/${param[4]}`);
      }

      console.log('Responses sent successfully:', response);
    } catch (error) {
      console.error('Error sending responses:', error);
    }
  };


  return (
    <div className="loginwallpaper">
  
      <div className='leftwallpaper'>
            {/* <div><img style={{width:"300px", height: "48px", paddingTop:"5%"}} src="logo (4).jpg"/></div> */}
            {/* <div><img className='imgstyle' src="/Logo Svg OG 3.png"/></div> */}
                <div className='leftwallpapercontainer'>
                    <div className='titlewallpaper'>
                        <span className='title'>Welcome to Precium AI Recuritment Platform</span>
                        {/* <span className='titlecontainer'>A platform to showcase your skills.</span> */}
                    </div>
                    {/* 10 general apt qu
10 technical interview questions 
5 cultural figment questions
2 python programming */}
                   <div className='itemwallpaper'>
                    <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span> Choose the correct answer</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskOutline */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Do not refresh</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span> Do not go back</span>
                        </div>
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Do not open other tab</span>
                        </div>
                       
                        <div className='loginitem'>
                            <span className='tickicon'>
                                {/* <TaskAltOutlinedIcon /> */}
                                <CheckCircleOutlined />
                            </span>
                            <span>Coding</span>
                        </div>
                    </div>
                </div>
            </div>

      <div className="rightwallpaper">
        <div className="rightwallpapercontainer">
          <h1>Help us to understand</h1>

          {questions.map((question, index) => (
            <div key={index} className="questionContainer">
              <p>{question}</p>
              <Input.TextArea
                placeholder="Your answer..."
                onChange={(e) => handleResponseChange(index, e.target.value)}
              />
            </div>
          ))}


          <div>
            <button
              className="button"
              onClick={handleSubmit}
            >
              <div
                className="buttoncontainer"
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Submit&emsp;</span>
                <span />
              </div>
            </button>
          </div>

          <div className="rightfooter">
            <p>Click the "Next" button to move on to the next question.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communication;
