
import React, { useEffect, useState } from 'react';
import './Speak.css';
import { Input, Progress, Spin, notification } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Config from "../../Config";
import { REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../Constant";
import AWS from 'aws-sdk';
import useRestrictedNavigation from '../RestricBackButton/RestrictBackButton';
// import axios from 'axios';

const Speak = ({ onSuccess }) => {
  const questions = [
    " Please discuss about your favourite holiday",
    " Please discuss about your favourite game",
    "Please discuss about your best friend ",
    "Please discuss about your favourite leader and why?",
    "Please discuss about your ambition?",
    "Please discuss how you spend your summer vacation ?",
    "Please discuss about the story of your last watched movie?",
    "Please discuss about your favourite actor or actress?",
    "Please discuss about your favourite food and how do you make it?",
    "Please discuss about your role model?"
  ];

  const [userResponses, setUserResponses] = useState(Array(questions.length).fill(''));
  const [randomQuestionIndex, setRandomQuestionIndex] = useState(0);
  const [recordingProgress, setRecordingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [AuidoUrl, setAuidoUrl] = useState('')
  const [timerValue, settimerValue] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false);
  const [lastProgress, setLastProgress] = useState(0);
  const [lastTimer, setLastTimer] = useState(0);
  const [recordingInProgress, setRecordingInProgress] = useState(false); // State to track recording progress
const [ShowButton, setShowButton] = useState(true)
  const navigate = useNavigate();

  const locate = window.location.href;
  const param = locate.split("/");
  console.log(timerValue,"mail")
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Warning",
      description:
        type,
    });
  };

  useRestrictedNavigation(`/${param[4]}`)


  
  const updateTimerValue = () => {
      settimerValue(prevValue => prevValue + 1);
  }
  useEffect(() => {
    const intervalId = setInterval(updateTimerValue, 1000) 
       // Clean up the interval on component unmount
       
 
    return () => clearInterval(intervalId);
  },[]);

  useEffect(() => {
    if(recording ){
    setTimer(timerValue)
    }

    if(timer == 45){
      setRecording(false)
      setShowButton(false)
      stopRecording()
    }
  }, [timerValue])
  console.log(timerValue,"timerval",timer)

  



  const handleAnswerChange = (index, answer) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[index] = answer;
      return updatedResponses;
    });
  };

  useEffect(() => {
    const initialRandomIndex = Math.floor(Math.random() * questions.length);
    setRandomQuestionIndex(initialRandomIndex);
    setTimer(0);
  }, []);


  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const s3 = new AWS.S3();
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle:true,
    }),
  });
  const startRecording = async () => {
    settimerValue(1)
    setTimer(0);
    try { 
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        setAudioBlob(blob);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  console.log(audioBlob, 'audioBlob')

  // const stopRecording = () => {
  //   settimerValue(0)
  //   setTimer(0)
  //   if (mediaRecorder && mediaRecorder.state !== 'inactive') {
  //     mediaRecorder.stop();
  //     setRecording(false);
  //   }
  // };
  useEffect(() => {
    let interval = null;
    if (recording) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else if (!recording && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recording, timer]);

  const stopRecording = () => {
    settimerValue(0);
    setTimer(0);
    setLastTimer(timer);
    setLastProgress(Math.floor(timer * 2.23));
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setRecording(false);
      console.log("Recording stopped. Audio Blob:", audioBlob);
    }
  };
console.log(randomQuestionIndex,"randomQuestionIndex")

const scoreId = sessionStorage.getItem('scoreId')

  // const sendDataToApi = async () => {

  //   if(!audioBlob){
  //     openNotificationWithIconError("Please provide an answer before submitting.");
  //     return;
  //   }
  //   else {
      
  //     setSubmitLoading(true)
  //     settimerValue(0)
  //     setTimer(0)
  //       const timestamp = new Date().toISOString();
  //       console.log(audioBlob,'hi')
  //           const params = {  
  //             Bucket: 'subashpublic',
  //             Key: `Recording_${timestamp}.wav`, // Update UserID and BotID accordingly
  //             Body: audioBlob,
  //           };
  //           console.log(audioBlob,'hi',params)
      
  //           return new Promise((resolve, reject) => {
  //             s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
  //               .on('httpUploadProgress', (progress) => {
  //                 const uploadedPercent = Math.round((progress.loaded / progress.total) * 100);
  //               })
           
  //               .send((err, data) => {
  //                 if (err) {
  //                   reject(err);
  //                 } else {
  //                   resolve(data);
  //                   console.log(data,"dataa",data.Location !== "" , data.Location)
  //                   if(data.Location !== ""){  
  //                     let body = {
  //                       email: param[4],
  //                       score_id : scoreId,
  //                       question: questions[randomQuestionIndex],
  //                       answer: data.Location
  //                     }
  //                     axios.post(`${Config.host}/speaking`,body)
  //                     .then((res) => {
  //                     if(res.status === 200){
  //                       onSuccess()
  //                       setSubmitLoading(false)
  //                       navigate(`/scores/${param[4]}`)
  //                     }
  //                       console.log(res,"response form")
                     
  //                     })
  //                     .catch((err) => {
                  
  //                     })
  //                   }
  //                 }
  //               });
  //           });
          
  //   } 
  // };


  const sendDataToApi = async () => {
    try {
      if (!audioBlob) {
        openNotificationWithIconError("Please provide an answer before submitting.");
        return;
      }
  
      // Debugging: Log audioBlob value
      console.log("Audio Blob:", audioBlob);
  
      setSubmitLoading(true);
      settimerValue(0);
      setTimer(0);
  
      const timestamp = new Date().toISOString();
      const params = {  
        Bucket: 'subashpublic',
        Key: `Recording_${timestamp}.wav`,
        Body: audioBlob,
      };
  
      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on('httpUploadProgress', (progress) => {
            const uploadedPercent = Math.round((progress.loaded / progress.total) * 100);
            // Debugging: Log upload progress
            console.log("Upload Progress:", uploadedPercent);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
              console.log("Upload Data:", data);
              if (data.Location !== "") {  
                let body = {
                  email: param[4],
                  score_id: scoreId,
                  question: questions[randomQuestionIndex],
                  answer: data.Location
                };
                axios.post(`${Config.host}/speaking`, body)
                  .then((res) => {
                    if (res.status === 200) {
                      onSuccess();
                      setSubmitLoading(false);
                      navigate(`/scores/${param[4]}`);
                    }
                    console.log("Response:", res.data);
                  })
                  .catch((err) => {
                    console.error("API Request Error:", err);
                  });
              }
            }
          });
      });
    } catch (error) {
      console.error("Error in sendDataToApi:", error);
      // Add appropriate error handling here
    }
  };
  const sendVoice=()=>{


  }
  return (
    <div className="rightwallpapervoice">
        {contextHolder}
      <div className="rightwallpapercontainervoi">
        <h1 className='hedforspk'>Section 3 - Verbal Communication and Language Fluency Assessment</h1>
                                 <p>Please click 'SUBMIT' if you have completed</p>

        <div className="questionContainer">
          <h2 className='questiontospk'>{questions[randomQuestionIndex]}</h2>
 

{
  ShowButton ? 
      <button  className="cliknlist"
      onClick={recording ? stopRecording : startRecording}>
        {recording ? 'Stop Recording' : 'Start Recording'}
      </button>
      :<></>
}

          <div className="progress-bar-container">
            <Progress percent={recording ? Math.floor(timer * 2.23) : lastProgress}  showInfo={false} status="active" />
            <div className="timerforspeak">
            {`${Math.floor((recording ? timer : lastTimer) / 60)}:${(recording ? timer : lastTimer) % 60 < 10 ? '0' : ''}${(recording ? timer : lastTimer) % 60}`}
            </div>
          </div>
        </div>

        <div>
          {submitLoading ? (
            <Spin className='submitSpin' size="large" />
          ) : (
            <button className="button"  onClick={sendDataToApi}>
              <div
                className="buttoncontainer"
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center', 
                  justifyContent: 'center',
                }}
              >
                <span >Submit&emsp;</span>
                <span/>
              </div>
          </button>

          )}
        </div>
      </div>
    </div>
  );
};

export default Speak;



