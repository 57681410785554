// module.exports={host:'https://apiwebsite.valuemymedicine.com'} 

// module.exports={host:'https://api-hiringtalentpro.valuehealthsolutions.com'} 
module.exports={host: process.env.REACT_APP_BASEURL} 
// module.exports={host:'http://50.17.75.15:9005'} 
// module.exports = { host : `https://hiringtalentpro.valuehealthai.com` }

// `${Config.host}/get-jobs`)


// import Config from "../Config";