// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainparent{
    width:100%;
    height:100vh;
    top:0;
    padding-top: 10%;
    /* margin-top: 10%; */
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("https://s3.amazonaws.com/angularminds.com/new-blog-images/tips-to-prepare-for-job-interview.svg");

}
.succcomp{
    color: #EC7224;
    font-weight: 700;
    font-size: 29px;
    margin-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Final/Final.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,KAAK;IACL,gBAAgB;IAChB,qBAAqB;IACrB,4BAA4B;IAC5B,qBAAqB;IACrB,wHAAwH;;AAE5H;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".mainparent{\n    width:100%;\n    height:100vh;\n    top:0;\n    padding-top: 10%;\n    /* margin-top: 10%; */\n    background-repeat: no-repeat;\n    background-size: 100%;\n    background-image: url(\"https://s3.amazonaws.com/angularminds.com/new-blog-images/tips-to-prepare-for-job-interview.svg\");\n\n}\n.succcomp{\n    color: #EC7224;\n    font-weight: 700;\n    font-size: 29px;\n    margin-top: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
