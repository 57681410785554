import React, { useState, useEffect } from 'react';
import './Login.css';
import axios from 'axios';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input, Select, Spin } from 'antd';

import { useNavigate } from 'react-router-dom';
import { notification, Space } from "antd";
import Config from "../../Config"

const Login = () => {

  const { Option } = Select;
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('')
  // const [name, setName]= useState('')
  const [error, setError] = useState('');
  const [error2, setError2] = useState('')
  const [college, setCollege] = useState('')
  const [course, setCourse] = useState('')
  const [yearOfPassing, setYearOfPassing] = useState('');
  const [secondaryMedium, setSecondaryMedium] = useState('');
  const [underGraduateDegree, setUnderGraduateDegree] = useState('');

  const [pgdegree, setpgdegree] = useState('');
  const [pgPassOut, setPgPassOut] = useState('');
  const [pgColleg, setPgCollege] = useState('');
  // const [AltPhoneNumber, setAltPhoneNumber] = useState('')
  const [location, setLocation] = useState('');
  const [working, setWorking] = useState("");
  const [ collegeId, setCollegeId]= useState("");
  
  const [otherMedium, setOtherMedium] = useState('');
  const [otherDegree, setOtherDegree] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');
  const [totalExperience, setTotalExperience] = useState('');
  const [lastSalary, setLastSalary] = useState('');
  const [workLocation, setWorkLocation] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [loading, setLoading] = useState(false); 
  const [isCampus, setIsCampus]= useState("");
  const [batch, setBatch]=useState('')


  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  // const handleNameChange = (e) => {
  //   setName(e.target.value);
  // };
  const handleCollegeChange = (e) => {
    setCollege(e.target.value);
  };


  const handleCollegeId = (e) => {
    setCollegeId(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);

  
  };


  const handleBatchChange = (e) => {
    setBatch(e.target.value);
  };
  const handleMediumChange = (value) => {
    setSecondaryMedium(value);

    // If "Others" is selected, clear otherMedium
    if (value !== 'Others') {
      setOtherMedium('');
    }
  };

  const handleUnderGraduateDegreeChange = (value) => {
    setUnderGraduateDegree(value);

    // If "Others" is selected, clear otherDegree
    if (value !== 'Others') {
      setOtherDegree('');
    }
  };

  const handleNumberChange = (event) => {
    const inputNumber = event.target.value;
    if (/^\d+$/.test(inputNumber) && inputNumber.length === 10) {
      setPhone(inputNumber);
      setError('');
    } else {
      setPhone('');
      setError('Please enter a valid 10-digit phone number');
    }
  };

  // const handleAltNumberChange = (event) => {
  //   const inputNumber = event.target.value;

  //   // Validate if it's a number and meets your criteria
  //   if (/^\d+$/.test(inputNumber) && inputNumber.length === 10) {
  //     setAltPhoneNumber(inputNumber);
  //     setError('');
  //   } else {
  //     // Handle invalid input and set an error message
  //     setAltPhoneNumber('');
  //     setError('Please enter a 10-digit phone number');
  //   }
  // };

  const handlepgDegreeChange = (e) => {
    setpgdegree(e.target.value) 
  }

  const handlepgCollegeChange = (e) => {
    setPgCollege(e.target.value)
  }

  console.log(email, phone, "1234")

  const openNotificationWithIcon1 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text} </strong>
        </div>
      ),

      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 1,
    });
  };
  //   const submitOpt = async () => {
  //     try {
  //       const response = await axios.post("http://localhost:3001/Authorization/create", { emailId: email });
  //       // console.log(response.data.status);
  //       if (response.data.status === "Ok") {
  //         navigate("/otp?/"+email+"");
  //       }
  //     } catch (error) {
  //       // Handle errors
  //       console.error(error);
  //     }
  //   };





  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!firstName || !lastName || !email || !phone || !college || !yearOfPassing || !secondaryMedium || !underGraduateDegree) {
      notification.error({
        message: "Please fill in all required fields marked with *"
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${Config.host}/sign_up`,

        {
          "first_name": firstName,
          "last_name": lastName,
          "email_id": email,
          "phone_number": phone,
          "secondary_medium": secondaryMedium,
          "ug_degree": underGraduateDegree,
          "ug_passed_out": yearOfPassing,
          "ug_college": college,
          "pg_degree": pgdegree,
          "pg_passed_out": pgPassOut,
          "pg_college": pgColleg,
          "currently_working": working,
          "is_pursuing":"",
          // "alt_phone_num": AltPhoneNumber,
          "location": location,
          "clg_or_Job_id":collegeId,
          "other_medium": otherMedium,
          "other_degree": otherDegree,
          "job_role": jobRole,
          "current_company": currentCompany,
          "total_experience": totalExperience,
          "work_location": workLocation,
          "last_salary": lastSalary,
          "notice_period": noticePeriod,
          "is_campus": "string",
          "Batch": batch
        }
      );
      console.log(response?.data?.response);
      if (response.status === 200) {

        if (response.data.status === 400) {
          openNotificationWithIcon2("error", response?.data?.response)
        }
        else {
          openNotificationWithIcon1("success", "Login Successful");
          navigate(`/intro/${email}`);
        }
      }


    } catch (error) {

      console.error(error);

      openNotificationWithIcon2("error", error.response.data.detail[0].msg)
    }
    setLoading(false);
  };
  const handleInput = (e) => {
    // Remove non-numeric characters using a regular expression
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <div className="loginwallpaper">
      
      <div className='leftwallpaper'>
        {/* <div><img className='imgstyle' src="/Frame 4.png" /></div> */}
        <div className='leftwallpapercontainer'>
          <div className='titlewallpaper'>
            <span className='title'>Welcome to AI Recruitment Hub</span>
            <div className='titlemini'>Your Gateway to Talent Acquisition Success!</div>
            {/* <span className='titlecontainer'>A platform to showcase your skills.</span> */}
          </div>

          <div className='itemwallpaper'>
            <div className='loginitem'>
              <span className='tickicon'>
                {/* <TaskOutline */}
                <CheckCircleOutlined />
              </span>
              <span>Medical Terminology Assessment</span>
            </div>

            <div className='loginitem'>
              <span className='tickicon'>
                {/* <TaskAltOutlinedIcon /> */}
                <CheckCircleOutlined />
              </span>
              <span>Language Proficiency Skills Assessment</span>
            </div>
            <div className='loginitem'>
              <span className='tickicon'>
                {/* <TaskAltOutlinedIcon /> */}
                <CheckCircleOutlined />
              </span>
              <span>Listening and Comprehension Skills Assessment</span>
            </div>
            <div className='loginitem'>
              <span className='tickicon'>
                {/* <TaskAltOutlinedIcon /> */}
                <CheckCircleOutlined />
              </span>
              <span>Verbal Communication Assessment</span>
            </div>
            <div className='loginitem'>
              <span className='tickicon'>
                {/* <TaskAltOutlinedIcon /> */}
                <CheckCircleOutlined />
              </span>
              <span>Critical Thinking and Aptitude Skills Assessment</span>
            </div>

          </div>
        </div>
      </div>
      <div className='rightwallpaper'>
        <div className='rightwallpapercontain'>
          {/* logo */}
          <div className='rightcontainer'>
            <div className='righttitlelogin' >
            Online Candidate Registration
            </div>

          </div>
          <div className='righttitleloginmini' >
            Please complete the form below for sign up
            </div>

          <div className='rightemailid'>
            <form onSubmit={handleFormSubmit}>
              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              First Name <span style={{ color: 'red' }}>*</span>
            </div>

                <Input 
                // defaultValue={'Shabari'}
                 id="outlined-basic" label="First Name" variant="outlined" placeholder='Enter Your First Name' style={{
                  width: "100%", height: "50px"
                }
                } onChange={handleFirstNameChange}/>
              </div>

              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Last Name <span style={{ color: 'red' }}>*</span>
            </div>
                <Input 
                // defaultValue={'Iyyappan'}
                 id="outlined-basic" label="Last Name" variant="outlined" placeholder='Enter Your Last Name' style={{
                  width: "100%", height: "50px"
                }
                } onChange={handleLastNameChange} />
              </div>


              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Higher Secondary Medium <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  placeholder="Higher secondary medium"
                  // label="!"
                  style={{ width: "100%", height: "50px",textAlign:"left" }}
                  onChange={(value) => handleMediumChange(value)}
                  value={secondaryMedium || null}
                >
                  <Option value="State board">State board</Option>
                  <Option value="Matric">Matric</Option>
                  <Option value="CBSE"> CBSE</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </div>

              {secondaryMedium === 'Others' && (


        <div className='inputparentdiv'>
        <div className='lastName' htmlFor="lastName">
Other Medium <span style={{ color: 'red' }}>*</span>
</div>
          <Input
            id="outlined-basic"
            type="text"
            label="Other Medium"
            variant="outlined"
            placeholder='Enter Your Medium'
            style={{
              width: "100%",
              height: "50px",
            }}
            value={otherMedium}
            onChange={(e) => setOtherMedium(e.target.value)}
          />
        </div>
      
      )} 
      

              {/* <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Alternate Phone <span style={{ color: 'red' }}>*</span>
            </div>

                <Input id="outlined-basic" type="number" label="Email Id" variant="outlined" placeholder='Enter Your  Alternative Phone' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handleAltNumberChange}
                  />
                {error && <p style={{ color: 'red' }}>{error}</p>}

              </div> */}

           

           



         

  

              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              UG Degree <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  label="Select Under Graduate Degree"
                  placeholder="Select Under Graduate Degree"
                  style={{ width: "100%", height: "50px",textAlign:"left"  }}
                  onChange={(value) => handleUnderGraduateDegreeChange(value)}
                  value={underGraduateDegree || null}
                >  
                  <Option value="B.Pharm">B.Pharm</Option>
                  <Option value="Pharm D">Pharm D</Option>
                  <Option value="Bio Technology">Bio Technology</Option>
                  <Option value="B.Sc (Nursing)">B.Sc (Nursing)</Option>
                  <Option value="B.Sc (Life Sciences)">B.Sc (Life Sciences)</Option>
                  <Option value="B.Physio">B.Physio</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </div>

              {underGraduateDegree === 'Others' && (
        <div className='inputparentdiv'>
          <Input
            id="outlined-basic"
            type="text"
            label="Other Degree"
            variant="outlined"
            placeholder='Enter Your Degree'
            style={{
              width: "100%",
              height: "50px",
            }}
            value={otherDegree}
            onChange={(e) => setOtherDegree(e.target.value)}
          />
        </div>
      )} 

<div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Year of passing <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  placeholder="Select Year of Passing"
                  style={{ width: "100%", height: "50px" ,textAlign:"left" }}
                  onChange={(value) => setYearOfPassing(value)}
                  value={yearOfPassing || null}
                >
                  <Option value="Final Year">Final Year</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2022">2022</Option>
                  <Option value="Before 2022">Before 2022</Option>
                </Select>
              </div>


              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              College Name <span style={{ color: 'red' }}>*</span>
            </div>
                <Input id="outlined-basic" type="text" label="Email Id" variant="outlined" placeholder='Enter Your College Name' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handleCollegeChange}
                  />
              </div>


              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              PG Degree
               {/* <span style={{ color: 'red' }}>*</span> */}
            </div>
                <Input id="outlined-basic" type="text" label="" variant="outlined" placeholder='Enter Your PG Degree/ NA' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handlepgDegreeChange}
                 />
              </div>

              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Year of passing(PG)
               {/* <span style={{ color: 'red' }}>*</span> */}
            </div>
                <Select
                  placeholder="Select Year of Passing"
                  style={{ width: "100%", height: "50px",textAlign:"left"  }}
                  onChange={(value) => setPgPassOut(value)}
                  value={pgPassOut || null}
                >
                  <Option value="Final Year">Final Year</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2022">2022</Option>
                  <Option value="Before 2022">Before 2022</Option>
                  <Option value="NA">NA</Option>
                </Select>
              </div>

              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
            PG College 
            {/* <span style={{ color: 'red' }}>*</span> */}
            </div>
                <Input id="outlined-basic" type="text" label="" variant="outlined" placeholder='Enter Your PG College/ NA' style={{
                  width: "100%", height: "50px"
                }
                } 
                onChange={handlepgCollegeChange}
                 />
              </div>


              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Is this assessment taken as a part of campus drive <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  placeholder="Assessment taken in campus"
                  style={{ width: "100%", height: "50px" ,textAlign:"left" }}
                  onChange={(value) => setIsCampus(value)}
                  value={isCampus || null}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                  <Option value="NA">NA</Option>
                </Select>
              </div>


{isCampus=='Yes' &&(
              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
               College Id <span style={{ color: 'red' }}>*</span>
            </div>
                <Input id="outlined-basic" type="text" label="Email Id" variant="outlined" placeholder='Enter Your College ID' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handleCollegeId} 
                 />
              </div>
)}

              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Currently Working <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  placeholder="Currently Working"
                  style={{ width: "100%", height: "50px",textAlign:"left"  }}
                  onChange={(value) => setWorking(value)}
                  value={working || null}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>

                </Select>
              </div>

              {working === 'Yes' && (
        <div>
          <div className='inputparentdiv'>
          <div className='lastName' htmlFor="lastName">
              Job Role <span style={{ color: 'red' }}>*</span>
            </div>
            <Input
              id="outlined-basic"
              type="text"
              label="Job Role"
              variant="outlined"
              placeholder='Pharmacist, Medical Scribing, Medical Coding, QA, etc'
              style={{
                width: "100%",
                height: "50px",
              }}
              value={jobRole}
              onChange={(e) => setJobRole(e.target.value)}
            />
          </div>

          <div className='inputparentdiv'>
          <div className='lastName' htmlFor="lastName">
              Current/Last Company <span style={{ color: 'red' }}>*</span>
            </div>
            
            <Input
              id="outlined-basic"
              type="text"
              label="Current Company"
              variant="outlined"
              placeholder='Enter Your Current Company'
              style={{
                width: "100%",
                height: "50px",
              }}
              value={currentCompany}
              onChange={(e) => setCurrentCompany(e.target.value)}
            />
          </div>

          <div className='inputparentdiv'>
          <div className='lastName' htmlFor="lastName">
              Total Work Experience in months <span style={{ color: 'red' }}>*</span>
            </div>
            <Input
              id="outlined-basic"
              type="text"
              label="Total Experience"
              variant="outlined"
              placeholder='Enter Your Total Experience'
              style={{
                width: "100%",
                height: "50px",
              }}
              value={totalExperience}
              onChange={(e) => setTotalExperience(e.target.value)}
            />
          </div>


          <div className='inputparentdiv'>
          <div className='lastName' htmlFor="lastName">
              Current or Latest Annual Salary(CTC)<span style={{ color: 'red' }}>*</span>
            </div>
            <Select
                  placeholder="Annual Salary"
                  style={{ width: "100%", height: "50px",textAlign:"left"  }}
                  onChange={(value) => setLastSalary(value)}
                  value={lastSalary|| null}
                >
                  <Option value="1-2Lakhs">1-2 Lakhs </Option>
                  <Option value="2-3Lakhs">2-3 Lakhs</Option>
                  <Option value="3-5Lakhs">3-5 Lakhs</Option>
                  <Option value="Above 5 Lakhs">Above 5 Lakhs</Option>
             
                </Select>
          </div>

          <div className='inputparentdiv'>
          <div className='lastName' htmlFor="lastName">
              Work Location <span style={{ color: 'red' }}>*</span>
            </div>
            <Input
              id="outlined-basic"
              type="text"
              label="Work Location"
              variant="outlined"
              placeholder='Enter Your Work Location'
              style={{
                width: "100%",
                height: "50px",
              }}
              value={workLocation}
              onChange={(e) => setWorkLocation(e.target.value)}
            />
          </div>





<div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Notice Period(In Days) <span style={{ color: 'red' }}>*</span>
            </div>
                <Select
                  placeholder="Notice Period"
                  style={{ width: "100%", height: "50px",textAlign:"left"  }}
                  onChange={(value) => setNoticePeriod(value)}
                  value={noticePeriod|| null}
                >
                  <Option value="0-15 days">0-15 days </Option>
                  <Option value="16-30 days">16-30 days</Option>
                  <Option value="31-45 days">31-45 days</Option>
                  <Option value="45-60 days">45-60 days</Option>
                  <Option value="Above 60 days">Above 60 days</Option>
                </Select>
              </div>
          </div>
      )}
              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Location(City/Town) <span style={{ color: 'red' ,fontSize:"10px"}}>*</span>
            </div>

                <Input id="outlined-basic" label="Location" variant="outlined" placeholder='Enter Your Current Location' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handleLocationChange} 
                 />
              </div>


              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Contact Number <span style={{ color: 'red' }}>*</span>
            </div>

                <Input
                //  defaultValue={'9965709123'}
                // inputMode="numeric"
                  id="outlined-basic"
                  onInput={handleInput}
                  label="Email Id" variant="outlined" placeholder='Enter Your Phone' style={{
                width: "100%", height: "50px"
                }
                } onChange={handleNumberChange} />
                {error && <p style={{ color: 'red' }}>{error}</p>}

              </div>

            
            <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Email <span style={{ color: 'red' }}>*</span>
            </div>
                <Input 
                // defaultValue={'shabari.abimanu@gmail.com'}
                 id="outlined-basic" type="Email" label="Email Id" variant="outlined" placeholder='Enter Your Mail Id' style={{
                  width: "100%", height: "50px"
                }
                } onChange={handleEmailChange} />
              </div>

  




              <div className='inputparentdiv'>
              <div className='lastName' htmlFor="lastName">
              Batch Number <span style={{ color: 'red' ,fontSize:"10px"}}>*</span>
            </div>

                <Input id="outlined-basic" label="Location" variant="outlined" placeholder='Enter Your Batch Number' style={{
                  width: "100%", height: "50px"
                }
                }
                 onChange={handleBatchChange} 
                 />
              </div>

              <div className='continuebutton'>
                <button className='button' disabled={loading}>
                  <div className='buttoncontainer' style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    {loading ? (
            <div className='buttoncontainer'>
              <span><Spin/></span>
            </div>
          ) : (
            <div className='buttoncontainer'>
              <span>NEXT&emsp;</span>
            </div>
          )}
                    <span>
                 
                    </span>
                  </div>
                </button>
                <div className='termandcondition'>
                  <p>
                  By clicking on NEXT, you are consenting to the
                    <span>
                      &nbsp;Terms of use
                    </span>
                    &nbsp;and
                    <span>
                      &nbsp;Privacy Policy
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login;