
import React, { useEffect, useState } from 'react';
import './Med.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input, Spin, Radio,notification} from 'antd'; // Import Spin component
import Config from "../../Config";

const MedHard = ({ onSuccess }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [questions, setQuestions] = useState([]);
  const [ButtonLoading, setButtonLoading] = useState(false)
  const [userResponses, setUserResponses] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(20 * 60)
  const [loadingmain, setLoadingmain] = useState(false);

  const locate = window.location.href
  const param = locate.split("/")
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Warning",
      description:
        type,
    });
  };

  const handleRadioChange = (e) => {
    setUserInput(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    fetchQuestions()
  }, [])

  const fetchQuestions = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${Config.host}/med_mcqs`);
      const apiQuestions = response.data.questions;
      const correctAnswersArray = apiQuestions.map((q) => q.answer);

      setQuestions(apiQuestions);
      setCorrectAnswers(correctAnswersArray);

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching questions:', error);
    }
  };

  const handleSubmit = async () => {
    const selectedChoice = document.querySelector('input[name="choice"]:checked');
    const choiceValue = selectedChoice ? selectedChoice.value : '';

    if (choiceValue) {

      setUserResponses((prevResponses) => [...prevResponses, choiceValue]);
    }
    console.log(userResponses, "response")


    if (currentQuestionIndex < questions.length) {

   

      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      try {
        setButtonLoading(true)
        setLoadingmain(true);
        const response = await axios.post(`${Config.host}/over_all_mcq`, {
          email: param[4],
          correct_answer: correctAnswers,
          user_answer: userResponses
        });

        if (response.status == 200) {
          onSuccess();
        }
        console.log('Responses sent successfully:', response.data);
      } catch (error) {
        console.error('Error sending responses:', error);
      }
      finally {
        setButtonLoading(false);
        setLoadingmain(false);
      }
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timerInterval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [])

  return (
    <div>
         {contextHolder}
      <div className='rightwallpapercontainermed'>
        <h1 className='medheadtit'>Medical Terminology Assessment</h1>
        {currentQuestionIndex < questions.length ? <></> : <p>Submit Your answers</p>}
        {questions && questions?.length > 0 && (
          <div>
            <h2 className='questionmcqhard'>{questions[currentQuestionIndex]?.question}</h2>
            {questions[currentQuestionIndex]?.options?.map((choice, index) => (
              <div key={index}
                onClick={() => handleRadioChange(choice)}
                className="choice-box">
                <Radio
                  type="radio"
                  id={`choice_${index}`}
                  name="choice"
                  value={choice}
                  buttonColor='rgba(0, 0, 0, 0.88)'
                  style={{ marginLeft: "40px" ,color:"FE602F"}}
                  onChange={handleRadioChange}
                  checked={userInput === choice}
                />
                {choice}
              </div>
            ))}
          </div>
        )}

        <div>
          {loadingmain ? (
            <Spin size="large" />
          ) : (
            <button className='buttonmed' onClick={handleSubmit} disabled={ButtonLoading}>
              <div className='buttoncontainer'
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                {currentQuestionIndex < questions.length ? <span>Next&emsp;</span> : <span>Submit&emsp;</span>}
                <span></span>
              </div>
            </button>
          )}
        </div>
        <div className='rightfooter'>
          <p>Click the "NEXT" button to move on to the next question.</p>
        </div>
      </div>
    </div>
  )
}

export default MedHard;
