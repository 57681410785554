// import React ,{useEffect, useState} from 'react';
// import './Med.css';
// import axios from 'axios';
// // import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
// // import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
// // import TextField from '@mui/material/TextField';
// // import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import { useNavigate } from 'react-router-dom';
// // import { ArrowForward } from '@material-ui/icons';
// import { CheckCircleOutlined } from '@ant-design/icons';
// import { Input } from 'antd';


// const MedMedium = () => {

//     const navigate = useNavigate();

//     const [email, setEmail] = useState('');
//     const [messages, setMessages] = useState([]);
//     const [loading , setLoading] = useState(true) 
//      const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//      const [userInput, setUserInput] = useState('');
//      const [questions, setQuestions] = useState([]);
//      const [userResponses, setUserResponses] = useState([]);
//      const initialRemainingTime = parseInt(localStorage.getItem('remainingTime')) || 20 * 60;
//      const [remainingTime, setRemainingTime] = useState(initialRemainingTime)
     

     


//      const locate = window.location.href
//      console.log(locate, "ee")
   
//      const param = locate.split("/")
//      console.log(param[4], "my_list")
   

     
//      const handleRadioChange = (e) => {
//         setUserInput(e.target.value);
//       };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };
//   useEffect(()=>{
//    fetchQuestions()
//   },[])

//   const fetchQuestions = async () => {
//     setLoading(true)
//   try {
//     const response = await axios.get('http://54.89.129.247:9001/med_mcq_medium');
//     const apiQuestions = response.data.questions;

//     // const formattedQuestions = Object.entries(apiQuestions).map(([question, choices]) => ({
//     //   question,
//     //   choices,
//     // }));
    

//     setQuestions(apiQuestions);
//     // setMessages(formattedQuestions[currentQuestionIndex].question);
//     setLoading(false)
//     console.log(response)
//   } catch (error) {
//     console.error('Error fetching questions:', error);
//   }
// };

// console.log(userResponses, "userresp")
// const handleSubmit = async () => {
//     const selectedChoice = document.querySelector('input[name="choice"]:checked');
//     // if (selectedChoice) {
//     //   setUserResponses((prevResponses) => [...prevResponses, selectedChoice.value]);
//     // //   setUserResponses('')
//     // }

//     // newlogic for string

//     const choiceValue = selectedChoice ? selectedChoice.value : '';

//     setUserResponses((prevResponses) => [...prevResponses, choiceValue]);
  

//     if (currentQuestionIndex + 1 < questions.length) {
//       setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//     } else {
//       // All questions answered, send responses to the server
//       try {
//         const response = await axios.post('http://54.89.129.247:9001/med_mcq_medium_validator', {
//           email: param[4],
//           response: userResponses,
//         });

//         if(response.status==200){
//             navigate(`/medhard/${param[4]}`)
//         }
//         console.log('Responses sent successfully:', response.data);
//         // Handle any further actions or redirects here
//       } catch (error) {
//         console.error('Error sending responses:', error);
//         // Handle errors
//       }
//     }
//   };


//   useEffect(() => {
//     const timerInterval = setInterval(() => {
//       setRemainingTime((prevTime) => {
//         if (prevTime > 0) {
            
//           return prevTime - 1;
//         } else {
//           // Time is up, you may want to handle this case
//           clearInterval(timerInterval);
//           return 0;
//         }
//       });
//     }, 1000); // Update every 1 second

//     return () => clearInterval(timerInterval); 
// },[])

// //   const submitOpt = async () => {
// //     try {
// //       const response = await axios.post("http://localhost:3001/Authorization/create", { emailId: email });
// //       // console.log(response.data.status);
// //       if (response.data.status === "Ok") {
// //         navigate("/otp?/"+email+"");
// //       }
// //     } catch (error) {
// //       // Handle errors
// //       console.error(error);
// //     }
// //   };





//     return (
//         <div className="loginwallpaper">
//            <div className='leftwallpaper'>
//             <div><img className='imgstyle' src="/Logo.png"/></div>
//                 <div className='leftwallpapercontainer'>
//                     <div className='titlewallpaper'>
//                         <span className='title'>welcome to precium AI Recuritment Platform</span>
                     
//                     </div>
//                     <div className='itemwallpaper'>
//                     <div className='loginitem'>
//                             <span className='tickicon'>
//                                 {/* <TaskAltOutlinedIcon /> */}
//                                 <CheckCircleOutlined />
//                             </span>
//                             <span> Choose the correct answer</span>
//                         </div>
//                         <div className='loginitem'>
//                             <span className='tickicon'>
//                                 {/* <TaskOutline */}
//                                 <CheckCircleOutlined />
//                             </span>
//                             <span>Total marks : 20</span>
//                         </div>
//                         <div className='loginitem'>
//                             <span className='tickicon'>
//                                 {/* <TaskAltOutlinedIcon /> */}
//                                 <CheckCircleOutlined />
//                             </span>
//                             <span> Total time 20 min</span>
//                         </div>
//                         <div className='loginitem'>
//                             <span className='tickicon'>
//                                 {/* <TaskAltOutlinedIcon /> */}
//                                 <CheckCircleOutlined />
//                             </span>
//                             <span>Each question carries 1 mark</span>
//                         </div>
                       
//                         <div className='loginitem'>
//                             <span className='tickicon'>
//                                 {/* <TaskAltOutlinedIcon /> */}
//                                 <CheckCircleOutlined />
//                             </span>
//                             <span> <div className='timerspan'>Remaining Time:<h1 className='timerh1'> {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</h1> </div></span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className='rightwallpaper'>
              
//                 <div className='rightwallpapercontainer'>
//                     <h1>MC Questions</h1>

//                 {/* <div className='rightHeader'>
//                     <h1>General MCQ Questions</h1>
//                 </div> */}
//                     {/* logo */}


//                     {questions?.length > 0 && (
//             <div>
//               <h2 className='questionmcq'>{questions[currentQuestionIndex].question}</h2>
//               {questions[currentQuestionIndex]?.options?.map((choice, index) => (
//                 <div key={index} className="choice-box">
//                     <input
//                     type="radio"
//                     id={`choice_${index}`}
//                     name="choice"
//                     value={choice}
//                     onChange={handleRadioChange}
//                     checked={userInput === choice}
//                   />
          
//                {choice}
//                 </div>
//               ))}
//             </div>
//           )}

// <div>
// <button className='buttonmed'  onClick={handleSubmit}>
//                                     <div className='buttoncontainer'
//                                     style={{
//                                         fontWeight: "700",
//                                         fontSize: "14px",
//                                         lineHeight: "100%",
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "center"
//                                     }}>
//                                         <span>Next&emsp;</span>
//                                         <span>
//                                 {/* <div style={{
//                                                 width: "32px",
//                                                 height: "28px"
//                                             }} /> */}
//                                         </span>
//                                     </div>
//                                 </button>
// </div>


// <div className='rightfooter'>
//     <p>After selecting the option click on Next button for next question</p>
// </div>
                  
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default MedMedium;