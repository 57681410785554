import React from 'react';
import "./Final.css"

const Final = () => {
  return (
    <div className='mainparent'>
        <div><h2 className='succcomp'>Congratulations!! Your have completed the assessments successfully!

<br/>
Our team will get back to you with the results soon!! </h2></div>
    </div>
  )
}

export default Final