import React, { useRef, useState, useEffect } from 'react';
// import Addjdheader from '../Header/Addjdheader';
import "./Score.css"
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Card, Input, Space, Table } from 'antd';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import Config from "../../Config";
import useRestrictedNavigation from '../RestricBackButton/RestrictBackButton';

// const data = [
//     {
//         key: '1',
//         name: 'John Brown',
//         mail_id: 32,
//         education: 4,
//         skills: "",
//         projects: " Artificial Intelligence, Machine Learning, Linear Regression, Logistic Regression, Decision Tree, Random Forest, XGBoost, LGBoost, KNN, SVM, ANN, CNN, OpenCV, YOLO, Naive Bayes, K-Means Clustering, DBSCAN, Python, Natural Language Processing, Pandas, Numpy, Seaborn, Matplotlib, Scikit Learn, RNN, LSTM, BERT, NLTK, Spacy, Textbolb, Tesnorflow, Keras, SQL, Tableau, Excel, AWS, GIT",
//         experience: ' Master of Computer Applications',
//         assesment_score: "",
//         brain_validation: "",
//         communication_score: ""
//     },
//     {
//         key: '1',
//         name: 'John Brown',
//         mail_id: 32,
//         education: 4,
//         skills: "",
//         projects: " Artificial Intelligence, Machine Learning, Linear Regression, Logistic Regression, Decision Tree, Random Forest, XGBoost, LGBoost, KNN, SVM, ANN, CNN, OpenCV, YOLO, Naive Bayes, K-Means Clustering, DBSCAN, Python, Natural Language Processing, Pandas, Numpy, Seaborn, Matplotlib, Scikit Learn, RNN, LSTM, BERT, NLTK, Spacy, Textbolb, Tesnorflow, Keras, SQL, Tableau, Excel, AWS, GIT",
//         experience: ' Master of Computer Applications',
//         assesment_score: "",
//         brain_validation: "",
//         communication_score: ""
//     },
//     {
//         key: '1',
//         name: 'John Brown',
//         mail_id: 32,
//         education: 4,
//         skills: "",
//         projects: " Artificial Intelligence, Machine Learning, Linear Regression, Logistic Regression, Decision Tree, Random Forest, XGBoost, LGBoost, KNN, SVM, ANN, CNN, OpenCV, YOLO, Naive Bayes, K-Means Clustering, DBSCAN, Python, Natural Language Processing, Pandas, Numpy, Seaborn, Matplotlib, Scikit Learn, RNN, LSTM, BERT, NLTK, Spacy, Textbolb, Tesnorflow, Keras, SQL, Tableau, Excel, AWS, GIT",
//         experience: ' Master of Computer Applications',
//         assesment_score: "",
//         brain_validation: "",
//         communication_score: ""
//     },
// ]
const Score = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableData, setTableData] = useState([]);
    const searchInput = useRef(null);
    const [ todayBest, setTodayBest ] = useState(0);

    const locate = window.location.href;
  const param = locate.split("/");

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    useRestrictedNavigation(`/${param[4]}`)

    const handleDelete = async (recordKey) => {
        try {
            // Make an API call to delete the record with the given key
            await axios.delete(`http://your-api-endpoint/${recordKey}`);
            
            // After successful deletion, update the table data
            const updatedData = tableData.filter((record) => record.key !== recordKey);
            setTableData(updatedData);
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };

    
    const columns = [
        {
            title: 'Name', 
            dataIndex: 'first_name',
            key: 'first_name',
            width: '30%',
            fontSize:"40px",
            // ...getColumnSearchProps('first_name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            // ...getColumnSearchProps('email'),
        },
      
      
        {
            title: 'Aptitude Score',    
            dataIndex: 'aptitude_score',
            key: 'aptitude_score',
            width: '20%',
            // ...getColumnSearchProps('aptitude_score'),
        },
        {
            title: 'Medium Score',
            dataIndex: 'medium_score',
            key: 'medium_score',
            width: '20%',
            // ...getColumnSearchProps('medium_score'),
        },
        {
            title: 'Hard Score',
            dataIndex: 'hard_score',
            key: 'hard_score',
            width: '20%',
            // ...getColumnSearchProps('hard_score'),
        },
        {
            title: 'Easy Score',
            dataIndex: 'easy_score',
            key: 'easy_score',
            width: '20%',
            // ...getColumnSearchProps('easy_score'),
        },
        {
            title: 'Summary Score',
            dataIndex: 'summary_score',
            key: 'summary_score',
            width: '20%',
            // ...getColumnSearchProps('summary_score'),
        },

        {
            title: 'Audio listen score',
            dataIndex: 'audiolistenscore',
            key: 'audiolistenscore',
            width: '20%',
            // ...getColumnSearchProps('audiolistenscore'),
        },
  

        {
            title: 'Total Score',
            dataIndex: 'user_audio_score',
            key: 'user_audio_score',
            sorter: (a, b) => a.user_audio_score - b.user_audio_score,
            width: '20%',
            // ...getColumnSearchProps('audiolistenscore'),
        },
        

        // {
        //     title: 'Action',
        //     dataIndex: 'Delete',
        //     key: 'Delete',
        //     width: '20%',
        //     backgroundColor:"white",
        //     render: (text, record) => (
        //         <DeleteOutlined
        //             style={{ color: 'white', cursor: 'pointer',fontSize:"20px" }}
        //             onClick={() => handleDelete(record.key)}
        //         />
        //     ),
        //     // ...getColumnSearchProps('Delete'),
        // },
    ];

    const newColumn = [
        {
            title: 'S.No', 
            dataIndex: 's_no',
            key: 'first_name',
            width: '10%',
            fontSize:"40px",
            render: (text, object, index) =>(  index + 1)
        },
        {
            title: 'Speaking round', 
            dataIndex: 'speaking',
            key: 'speaking',
            width: '15%',
            fontSize:"40px",
        },
        {
            title: 'Writing round', 
            dataIndex: 'writing',
            key: 'writing',
            width: '15%',
            fontSize:"40px",
        },
        {
            title: 'Listening round', 
            dataIndex: 'listening',
            key: 'listening',
            width: '15%',
            fontSize:"40px",
        },
        {
            title: 'Total Score', 
            dataIndex: 'total_score',
            key: 'total_score',
            width: '15%',
            fontSize:"40px",
        },
        {
            title: 'Date', 
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            fontSize:"40px",
        },
        {
            title: 'Time', 
            dataIndex: 'time',
            key: 'time',
            width: '30%',
            fontSize:"40px",
        },
    ]

    let scoreId = sessionStorage.getItem('scoreId')

    console.log(param, 'paramsId')

    const fetchTableData = async () => {
        try {

            let body = {
                email_id : param[4]
            }

            const response = await axios.post(`${Config.host}/findone/userscore`, body);

            const resData = response.data.detail;
            const todayBestScoreFind = resData.find( obj => obj.Today_Best_Score && Array.isArray(obj.Today_Best_Score) )
            const todayBestScore = todayBestScoreFind.Today_Best_Score[0].total_score
            console.log( todayBestScore, 'resData');

            if ( todayBestScore != '' && todayBestScore != null ){
                setTodayBest(JSON.parse(todayBestScore))
            } else{
                setTodayBest(0)
            }

            let arr=[];
            for ( let i=0; i < resData.length; i++ ){
                let obj = {
                    speaking : resData[i].speaking,
                    writing : resData[i].writing,
                    listening : resData[i].listening,
                    total_score : resData[i].total_score,
                    date: resData[i].created_dt,
                    time: resData[i].time
                }
                arr.push(obj);
            }
            setTableData(arr);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, []);


    return (
        <div className='assesscoretab'>
             {/* <div><img className='imgstyle' src="/Logo.png"/></div> */}


            <div  style={{ paddingTop:"1%",paddingLeft:"5%", paddingRight:"5%"}}>
                <Table columns={newColumn} dataSource={tableData} />
            </div>
            <Card 
            className='todayBestScoreCard'
            title={`Your today's best score`}
            >
                <h2 className='todayBestScore'>{todayBest}</h2>
            </Card>
            {/* <a>Return to courses</a> */}
        </div>
    )
}

export default Score;