import logo from './logo.svg';
import './App.css';
import Login from './Components/Login/Login';
import { Route, Routes } from 'react-router-dom';
import Interview from './Components/Home/Interview';
import LRBrain from './Components/LRBrain/LRBrain';
import Communication from './Components/Communication/Communication';
import Technical from './Components/Technical/Technical';
import Final from './Components/Final/Final';
import Voicetotext from './Components/VoicetoText/Voicetotext';
import MedEasy from './Components/Med/MedEasy';
import MedMedium from './Components/Med/MedMedium';
import MedHard from './Components/Med/MedHard';
import Intro from './Components/Intro/Intro';
import Intro2 from './Components/Intro/Intro2';
import Speak from './Components/Speak/Speak';
import Score from './Components/Score/Score'; 
import VoiceFile from './Components/Speak/VoiceFile';
import './MediaQuery.css'

function App() {
  return ( 
    <div className="App">
        <Routes>
          <Route path="home/:param1" element={<Interview/>}></Route>  
          <Route path="medeasy/:param1" element={<MedEasy/>}></Route>
          <Route path="medmedium/:param1" element={<MedMedium/>}></Route>
          <Route path="medhard/:param1" element={<MedHard/>}></Route>
          <Route path="/" element={<Login/>}></Route>
          <Route path="lrbrain/:param1" element={<LRBrain/>}></Route>
          <Route path="communication/:param1" element={<Communication/>}></Route>
          <Route path="/technical/:id" element={<Technical/>}></Route>
          <Route path="voice/:param1" element={<Voicetotext/>}></Route>
          <Route path="final" element={<Final/>}></Route>
          <Route path="intro/:param1" element={<Intro/>}/>
          <Route path="/:email" element={<Intro2/>}/>
          <Route path="speak/:param1"  element={<Speak/>}/>
          <Route path="scores/:param1"  element={<Score/>}/>
          <Route path="vf" element={<VoiceFile/>}/>
          
        </Routes>
    </div>
  );
}

export default App;
