



import React, { useEffect, useState } from 'react';
import './Technical.css';
import { Input, Spin,notification } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Config from "../../Config";

const Technical = ({ onSuccess }) => {
  const questions=[
    "Please discuss  about your favourite holiday",
    "Please discuss about your favourite games",
    "Please discuss about your best friend ",
    "Please discuss about your favourite leader and why?",
    "Please discuss about your ambition?",
    "Please discuss how you spend your summer vacation ?",
    "Please discuss about the story of your last watched movie?",
    "Please discuss about your favourite actor or actress?",
    "Please discuss about your favourite food and how do you make it?",
    "Please discuss about your role model?"
  ];

  const [userResponses, setUserResponses] = useState([]);
  const [randomQuestionIndex, setRandomQuestionIndex] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Warning",
      description:
        type,
    });
  };

  const navigate= useNavigate();

  const locate = window.location.href;
  const param = locate.split("/");
  console.log("parsm", typeof param[4])
  const email= param[4]

  const handleAnswerChange = (answer) => {
    const words = answer.split(' ').filter((word) => word.trim() !== '');

    // if (words.length <= 150) {
      setUserResponses((prevResponses) => {
        const updatedResponses = [...prevResponses];
        updatedResponses[randomQuestionIndex] = answer;
        return updatedResponses;
      });

      setWordCount(words.length);
    // }
  };


  

  let scoreId = sessionStorage.getItem('scoreId')

  const handleSubmit = async () => {


    if (!userResponses[randomQuestionIndex]) {
      openNotificationWithIconError("Please provide an answer before submitting.");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(`${Config.host}/Writing`, {
        email: param[4],
        score_id : scoreId,
        question: [questions[randomQuestionIndex]],
        answer: [userResponses[randomQuestionIndex]],
      });
      if(response.status === 200){
        onSuccess();
      }
      console.log('Responses sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending responses:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialRandomIndex = Math.floor(Math.random() * questions.length);
    setRandomQuestionIndex(initialRandomIndex);
  }, []);

  return (
    <div className="rightwallpapertech">
        {contextHolder}
      <div className='rightwallpapercontainertech'>
        <h1 className='techniheadings'>Section 1 - Grammar and Language Proficiency Skills Assessment</h1>
        <p className='textparafortech'>Please write a summary on the assigned topic with no fewer than 50 words.</p>

        <div className="questionContainer">
          <h3 style={{textAlign:"left"}}>{questions[randomQuestionIndex]}</h3>
          <Input.TextArea style={{height:"280px"}}  spellCheck='false'  onChange={(e) => handleAnswerChange( e.target.value)} placeholder="Your answer..." />
          {/* <p>{wordCount}/150 Words</p> */}
        </div>

        <div>
          {loading ? (
            <Spin className='submitSpin' size="large" />
          ) : (
            <button className='button' onClick={handleSubmit}>
              <div className='buttoncontainer'
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <span>Next&emsp;</span>
                <span></span>
              </div>
            </button>
          )}
        </div>       
      </div>
    </div>
  );
};

export default Technical;

