import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRestrictedNavigation = (redirectTo) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      navigate(redirectTo);
    };

    window.history.pushState(null, null, window.location.pathname); // Ensure current location is added to history
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, redirectTo]);
};

export default useRestrictedNavigation;
