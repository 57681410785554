import React from 'react'

const Transition2 = ({onSuccess}) => {

    const handleSubmit=()=>{
        onSuccess()
    }
  return (
    <div className="loginwallpaper">
      {/* ... (your existing code) ... */}
   
      <div className="rightwallpapervoice">
        <div className="rightwallpapercontainervoi">

       
             
          <h3 className='voicehedrighttrs1'>Congratulations! You have successfully completed the Section 2.</h3>
          <h3 className='voicehedrighttrs2'>Please click on the "Next" button to proceed to the next section</h3>

 


          <div>
            <button className="button"  
            onClick={handleSubmit}>
              <div
                className="buttoncontainer"
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%', 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Next&emsp;</span>
                <span/>
              </div>
            </button>
          </div>

          <div className="rightfooter">
            {/* <p>After selecting the option, click on the submit button for the next question</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transition2